<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="approve_sms" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('approve_sms')">
                            <yes-no-selectbox v-model="formData.approve_sms"
                                             :validate-error="errors[0]">
                            </yes-no-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="approve_email" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('approve_email')">
                            <yes-no-selectbox v-model="formData.approve_email"
                                              :validate-error="errors[0]">
                            </yes-no-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="approve_call" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('approve_call')">
                            <yes-no-selectbox v-model="formData.approve_call"
                                              :validate-error="errors[0]">
                            </yes-no-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components
import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox"

//Pages

// Services

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        graduateData: {
            type: Object,
            default: {}
        },
    },

    components: {
        ValidationObserver,
        ValidationProvider,

        YesNoSelectbox
    },

    data() {
        return {
            formData: {}
        }
    },
    created() {
        this.setData()
    },
    methods: {
        setData(){
            this.formData = {
                approve_sms: 'E',
                approve_email: 'H',
                approve_call: 'H'
            }
        },

        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.$toast.success(this.$t('successfully_saved'))
                this.$emit('updateSuccess')
            }
        }
    }
}
</script>

