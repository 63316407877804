<template>
    <div>
        <b-row>
            <b-col md="6">
                <ValidationObserver ref="formValidate">
                    <ValidationProvider name="do_you_think_to_do_master_in_corp" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('do_you_think_to_do_master_in_corp')">
                            <yes-no-selectbox v-model="formData.do_you_think_to_do_master_in_corp"
                                              :validate-error="errors[0]">
                            </yes-no-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </ValidationObserver>
            </b-col>
            <b-col cols="6" class="d-flex justify-content-end">
                <b-button class="mt-4" variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Template

// Components
import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox"

//Pages

// Services

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        graduateData: {
            type: Object,
            default: {}
        },
    },

    components: {
        ValidationObserver,
        ValidationProvider,
        YesNoSelectbox
    },

    data() {
        return {
            formData: {},
        }
    },
    created() {
        this.setData()
    },
    methods: {
        setData(){
            this.formData = {
                do_you_think_to_do_master_in_corp: 'E'
            }
        },

        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.$toast.success(this.$t('successfully_saved'))
                this.$emit('updateSuccess')
            }
        }
    }
}
</script>

