<template>
    <div>
        <multiselect v-model="selected"
                     label="text"
                     track-by="value"
                     :placeholder="$t('search')"
                     open-direction="bottom"
                     :options="options"
                     :searchable="true"
                     :loading="isLoading"
                     :internal-search="false"
                     :close-on-select="true"
                     :show-no-results="true"
                     :select-label="''"
                     :selected-label="''"
                     :deselect-label="''"
                     @search-change="search"
                     @input="handleInput($event)"
                     :class="getClass"
        >
            <span slot="noOptions"></span>
            <span slot="noResult">{{ $t('no_result') }}</span>
        </multiselect>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
    </div>
</template>

<script>
    // Services
    import SchoolService from '@/services/SchoolService';

    // Helpers
    import setSelected from '@/helpers/setSelected';
    import handleInput from '@/helpers/handleInput'

    // Other
    import qs from 'qs';

    export default {
        props: {
            countryId: {
                type: Number,
                default: null
            },
            isCountryRequired: {
                type: Boolean,
                default: false
            },
            value: {
                default: null
            },
            validateError: {
                type: String,
                default: ''
            },
            valueType: {
                type: String,
                default: 'id'
            },
            valueObject: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                isLoading: false,
                selected: null,
                options: []
            }
        },
        computed: {
            getClass() {
                let selectClass = '';
                if (this.validateError) {
                    selectClass = 'box-border-color';
                }

                return selectClass;
            }
        },
        watch: {
            countryId: function(newValue, oldValue) {
                if (newValue && newValue != oldValue) {
                    this.get();
                }
            },
            value: function(newValue) {
                if (this.options.length > 0) {
                    this.selected = this.options.filter(c => c.value == newValue);
                }
            },
            valueObject: {
                deep: true,
                handler: function(newValue, oldValue) {
                    if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
                        if (newValue.value && newValue.text) {
                            this.options.push(newValue);
                        }
                        this.selected = this.setSelected(this.value, this.options)
                    }
                }
            }
        },
        created() {
            if (this.valueObject) {
                if(this.valueObject.value && this.valueObject.text) {
                    this.options.push(this.valueObject);
                }
                this.selected = this.setSelected(this.value, this.options)
            }
        },
        methods: {
            handleInput: handleInput,
            setSelected: setSelected,
            search(key) {
                if (key.length > 2) {
                    if (this.isCountryRequired && !this.countryId) {
                        return;
                    }
                    this.clearOptions()

                    let filter = {};
                    if (this.countryId) {
                        filter.country_id = this.countryId;
                    }

                    // Filter
                    filter.name = key

                    const config = {
                        params: {
                            filter: filter,
                            sort: this.getLocaleField('name'),
                            limit: -1
                        },
                        paramsSerializer: (params) => qs.stringify(params, {encode: false})
                    };

                    SchoolService.getAll(config)
                                 .then(response => {
                                     const data = response.data.data;
                                     data.map(item => {
                                         this.options.push({
                                             value: item[this.valueType],
                                             text: item.name
                                         });
                                     });
                                 })
                                 .then(() => {
                                     this.selected = this.setSelected(this.value, this.options)
                                 })
                }
            },
            clearOptions() {
                this.options = [];
                this.selected = null;
            }
        }
    }
</script>
