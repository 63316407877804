<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="work_status" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('work_status')">
                            <multi-selectbox v-model="formData.work_status"
                                             :multiple="false"
                                             :options="workStatusOptions"
                                             :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="not_working_reason" rules="required" v-slot="{errors}">
                        <b-form-group :label="$t('not_working_reason')">
                            <multi-selectbox v-model="formData.not_working_reason"
                                             :multiple="false"
                                             :options="notWorkStatusOptions"
                                             :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="job_corporation" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('job_corporation')">
                            <b-form-input v-model="formData.job_corporation"
                                          :placeholder="$t('job_corporation')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="job_sector" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('job_sector')">
                            <multi-selectbox v-model="formData.job_sector"
                                             :multiple="false"
                                             :options="sectorOptions"
                                             :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="positional_status" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('positional_status')">
                            <multi-selectbox v-model="formData.positional_status"
                                             :multiple="false"
                                             :options="positionalOptions"
                                             :validate-error="errors[0]">
                            </multi-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                    <ValidationProvider name="position_detail" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('position_detail')">
                            <b-form-input v-model="formData.position_detail"
                                          :placeholder="$t('position_detail')"
                                          :state="errors[0] ? false : null"/>
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox"

//Pages

// Services

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        graduateData: {
            type: Object,
            default: {}
        },
    },

    components: {
        ValidationObserver,
        ValidationProvider,

        MultiSelectbox
    },

    data() {
        return {
            formData: {},
            workStatusOptions: [
                {
                    text: this.$t('working'),
                    value: 'working'
                },
                {
                    text: this.$t('not_working'),
                    value: 'not_working'
                }
            ],
            notWorkStatusOptions: [
                {
                    text: this.$t('not_working_in_military'),
                    value: 'not_working_in_military'
                },
                {
                    text: this.$t('not_working_no_info'),
                    value: 'not_working_no_info'
                },
                {
                    text: this.$t('not_working_other'),
                    value: 'not_working_other'
                },
                {
                    text: this.$t('not_working_education'),
                    value: 'not_working_education'
                },
                {
                    text: this.$t('not_working_marriage'),
                    value: 'not_working_marriage'
                },
                {
                    text: this.$t('not_working_searching_job'),
                    value: 'not_working_searching_job'
                }
            ],
            sectorOptions: [
                {
                    text: 'Bankacılık',
                    value: 'banka'
                },
                {
                    text: 'Yazılım',
                    value: 'Yazılım'
                }
            ],
            positionalOptions: [
                {
                    text: 'Girişimci/İş Yeri Sahibi',
                    value: 'Girişimci/İş Yeri Sahibi'
                },
                {
                    text: 'Yazılım Uzmanı',
                    value: 'Yazılım Uzmanı'
                },
                {
                    text: 'Öğretim Elemanı',
                    value: 'Öğretim Elemanı'
                }
            ],
        }
    },
    created() {
        this.setData()
    },
    methods: {
        setData(){
            this.formData = {
                work_status: "working",
                not_working_reason: "not_working_searching_job",
                job_corporation: this.graduateData.working_corporation,
                job_sector: this.graduateData.sector,
                positional_status: this.graduateData.positional_status,
                position_detail: this.graduateData.position_detail
            }
        },

        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.$toast.success(this.$t('successfully_saved'))
                this.$emit('updateSuccess')
            }
        }
    }
}
</script>

