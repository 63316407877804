<template>
    <div>
        <ValidationObserver ref="formValidate">
        <b-row>
            <b-col md="4">
                <ValidationProvider name="faculty_code" rules="required" v-slot="{errors}">
                    <b-form-group :label="$t('faculty')">
                        <faculty-selectbox v-model="formData.faculty_code"
                                           :validate-error="errors[0]">
                        </faculty-selectbox>
                    </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="department_code" rules="required" v-slot="{errors}">
                    <b-form-group :label="$t('department')">
                        <department-selectbox v-model="formData.department_code"
                                              :is_faculty_code_required="true"
                                              :faculty_code="formData.faculty_code"
                                              :validate-error="errors[0]">
                        </department-selectbox>
                    </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="program_code" rules="required" v-slot="{errors}">
                    <b-form-group :label="$t('program')">
                        <program-selectbox v-model="formData.program_code"
                                              :is_department_code_required="true"
                                              :department_code="formData.department_code"
                                              :validate-error="errors[0]">
                        </program-selectbox>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col md="4">
                <ValidationProvider name="national_id" rules="required" v-slot="{ errors }">
                    <b-form-group :label="$t('national_id')">
                        <b-form-input maxlength="11"
                                      v-model="formData.national_id"
                                      :placeholder="$t('national_id')"
                                      :state="errors[0] ? false : null"/>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                    </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="name" rules="required" v-slot="{ errors }">
                    <b-form-group :label="$t('name')">
                        <b-form-input v-model="formData.name"
                                      :placeholder="$t('name')"
                                      :state="errors[0] ? false : null"/>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                    </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="surname" rules="required" v-slot="{ errors }">
                    <b-form-group :label="$t('surname')">
                        <b-form-input v-model="formData.surname"
                                      :placeholder="$t('surname')"
                                      :state="errors[0] ? false : null"/>
                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                    </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="birthdate" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('birthdate')">
                        <v-date-picker v-model="formData.birthdate"
                                       :locale="$i18n.locale"
                                       is-expanded
                                       :masks="masks"
                                       :max-date="new Date()"
                                       :popover="{ 'visibility': 'click' }">

                            <template v-slot="{ inputValue, inputEvents }">
                                <b-input-group>
                                    <b-form-input :readonly="true"
                                                  :value="inputValue"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  v-on="inputEvents"></b-form-input>
                                    <b-input-group-append>
                                        <b-button variant="outline-secondary" class="btn-40" disabled>
                                            <i class="ri-calendar-line"></i></b-button>
                                    </b-input-group-append>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-input-group>
                            </template>
                        </v-date-picker>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col md="4">
                <ValidationProvider name="graduate_semester" rules="required" v-slot="{errors}">
                    <b-form-group :label="$t('graduate_semester')">
                        <academic-years-selectbox v-model="formData.graduate_semester"
                                           :validate-error="errors[0]">
                        </academic-years-selectbox>
                    </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="graduate_period" rules="required" v-slot="{errors}">
                    <b-form-group :label="$t('graduate_period')">
                        <semester-type-selectbox v-model="formData.graduate_period"
                                                  :validate-error="errors[0]">
                        </semester-type-selectbox>
                    </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="graduate_date" rules="required" v-slot="{valid, errors}">
                    <b-form-group :label="$t('graduate_date')">
                        <v-date-picker v-model="formData.graduate_date"
                                       :locale="$i18n.locale"
                                       is-expanded
                                       :masks="masks"
                                       :max-date="new Date()"
                                       :popover="{ 'visibility': 'click' }">

                            <template v-slot="{ inputValue, inputEvents }">
                                <b-input-group>
                                    <b-form-input :readonly="true"
                                                  :value="inputValue"
                                                  :state="errors[0] ? false : (valid ? true : null)"
                                                  v-on="inputEvents"></b-form-input>
                                    <b-input-group-append>
                                        <b-button variant="outline-secondary" class="btn-40" disabled>
                                            <i class="ri-calendar-line"></i></b-button>
                                    </b-input-group-append>
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                </b-input-group>
                            </template>
                        </v-date-picker>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col cols="12" lg="12" class="d-flex justify-content-end">
                <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
            </b-col>
        </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components
import FacultySelectbox from '@/components/interactive-fields/FacultySelectbox'
import DepartmentSelectbox from '@/components/interactive-fields/DepartmentSelectbox'
import ProgramSelectbox from '@/components/interactive-fields/ProgramSelectbox'
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox"
import SemesterTypeSelectbox from "@/components/interactive-fields/SemesterTypeSelectbox"

//Pages

// Services

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        graduateData: {
            type: Object,
            default: {}
        },
    },

    components: {
        ValidationObserver,
        ValidationProvider,

        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        AcademicYearsSelectbox,
        SemesterTypeSelectbox
    },

    data() {
        return {
            formData: {},
            masks: {
                input: "DD-MM-YYYY",
            },
        }
    },
    created() {
        this.setData()
    },
    methods: {
        setData(){
            this.formData = {
                national_id: this.graduateData.national_id,
                name: this.graduateData.name,
                surname: this.graduateData.surname,
                graduate_semester: this.graduateData.semester,
                graduate_period: this.graduateData.period,
            }
        },

        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.$toast.success(this.$t('successfully_saved'))
                this.$emit('updateSuccess')
            }
        }
    }
}
</script>

