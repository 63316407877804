<template>
    <div>
        <ValidationObserver ref="formValidate">
            <b-row>
                <b-col md="4">
                    <ValidationProvider name="work_start_date" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('work_start_date')">
                            <select-date v-model="formData.work_start_date"
                                         :validationError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="work_leave_date" rules="" v-slot="{valid, errors}">
                        <b-form-group :label="$t('work_leave_date')">
                            <select-date v-model="formData.work_leave_date"
                                         :validationError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="corporation" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('corporation')">
                            <b-form-input v-model="formData.corporation"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="corp_department" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('corp_department')">
                            <b-form-input v-model="formData.corp_department"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="working_type" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('working_type')">
                            <b-form-input v-model="formData.working_type"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col md="4">
                    <ValidationProvider name="working_tract" rules="required" v-slot="{valid, errors}">
                        <b-form-group :label="$t('working_tract')">
                            <b-form-input v-model="formData.working_tract"
                                          :state="errors[0] ? false : null" />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12" class="d-flex justify-content-end">
                    <b-button variant="primary" @click="saveForm">{{ $t('save') }}</b-button>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>
<script>
// Template

// Components

//Pages

// Services

// Others
import {ValidationObserver, ValidationProvider} from 'vee-validate'

export default {
    props: {
        graduateData: {
            type: Object,
            default: {}
        },
    },

    components: {
        ValidationObserver,
        ValidationProvider
    },

    data() {
        return {
            formData: {}
        }
    },
    created() {

    },
    methods: {
        async saveForm(){
            const isValid = await this.$refs.formValidate.validate();
            if (isValid) {
                this.$toast.success(this.$t('successfully_saved'))
                this.$emit('updateSuccess')
            }
        }
    }
}
</script>

